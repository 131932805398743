import request from "@/utils/request"
//案例数据一览查询
export function listPage(data) {
    return request({
        url: '/plan/listPage',
        method: 'post',
        data
    })
}
//案例详情
export function getPlanDetail(data) {
    return request({
        url: '/plan/getPlanDetail',
        method: 'get',
        params: data
    })
}
//案例-推送企业列表
export function pushCompanyPage(data) {
    return request({
        url: '/plan/pushCompanyPage',
        method: 'post',
        data
    })
}
//案例-推送客户经理列表
export function pushManagerPage(data) {
    return request({
        url: '/plan/pushManagerPage',
        method: 'post',
        data
    })
}
//案例数据批量导出
export function exportPlan(data) {
    return request({
        url: '/plan/exportPlan',
        method: 'post',
        responseType: "blob",
        data
    })
}
//服务记录分页列表
export function loglistPage(data) {
    return request({
        url: '/service/log/listPage',
        method: 'post',
        data
    })
}
//服务记录详情
export function getdetails(data) {
    return request({
        url: '/service/log/details',
        method: 'get',
        params: data
    })
}
//导出服务记录
export function serviceexport(data) {
    return request({
        url: '/service/log/export',
        method: 'post',
        responseType: "blob",
        data
    })
}
